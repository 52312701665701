<template>
    <div class="profile_notes">
        <div class="title">
            <ul class="tab_list">
                <li @click="tab = 1" :class="{'active' : tab == 1}">Workouts</li>
                <li @click="tab = 2" :class="{'active' : tab == 2}">Workout Programs</li>
            </ul>
            <div class="right_side">
                <div class="search_panel">
                    <div class="form-field">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <page-filter v-model="perPage" />
            </div>
        </div>
        <div class="notes_wpr" v-if="tab == 1">
            <div class="loader_area3" v-if="contactWorkoutLoader"><quote-loader/></div>
            <template v-else>
                <ul class="card_listing" v-if="contactWorkouts.total">
                    <li  v-for="(workout, w) of contactWorkouts.data" :key="w">
                        <profile-card v-bind:actions="workoutActions" :action-handler="workoutActionHandler" :selected-row="workout.workout">
                            <h4 class="mb-3 pointer" @click="viewWorkout(workout)"><img src="@/assets/images/kettlebell-alt.svg">{{workout.workout.name}}</h4>
                            <div class="card_content">
                                <h5 class="pb-3">Ratings:
                                    <star-rating 
                                        :increment="0.01"
                                        :max-rating="5"
                                        :rating="workout.ratings"
                                        active-color="#2f7eed"
                                        active-border-color="#2f7eed"
                                        :border-width="1"
                                        border-color="#d5d5d5"
                                        :show-rating="false"
                                        :star-size="10"
                                        :read-only="true"
                                        :rounded-corners="true"
                                        inactive-color="#d5d5d5"
                                    ></star-rating>
                                </h5>
                                <h5>Notes:</h5>
                                <p>{{ workout.completed ? workout.completed.notes : 'N/A' }}</p>
                            </div>
                            <div class="card_footer">
                                <h5>Assigned on:</h5>
                                <span class="date">
                                    {{ moment.utc(workout.created_at).local().format('ll | LT') }}
                                    <span class="text-danger" v-if="workout.deleted_at">[deleted]</span>
                                </span>
                            </div>
                        </profile-card>
                    </li>
                </ul>
                <div class="empty_list" v-if="contactWorkouts.total == 0 && !contactWorkoutLoader">
                    <img src="@/assets/images/empty_state.svg">
                    <h4>No Records Found!</h4>
                </div>
            </template>
        </div>
        <div v-if="tab == 1">
            <div class="pagination">
                <pagination v-model="workoutParams.page" :pages="contactWorkouts.last_page" @update:modelValue="handleWorkoutPagination"/>
            </div>
            <div class="form_view" v-if="showWorkout">
                <button class="close_btn pointer" @click="showWorkout = false"><i class="fas fa-times"></i></button>
                <workouts-chart :contact="contact" :workout-id="selectedWorkoutRelation.id" />
            </div>
        </div>
        <div class="notes_wpr" v-if="tab == 2">
            <div class="loader_area3" v-if="contactWorkoutPlanLoader"><quote-loader/></div>
            <template v-else>
                <ul class="card_listing" v-if="contactWorkoutPlans.total">
                    <li v-for="(plan, p) of contactWorkoutPlans.data" :key="p">
                        <profile-card v-bind:actions="workoutPlanActions" :action-handler="workoutPlanActionHandler" :selected-row="plan.workout_plan">
                            <h4 class="mb-3 pointer" @click="viewWorkoutPlan(plan)"><img src="@/assets/images/kettlebell-alt.svg">{{plan.workout_plan.name}}</h4>
                            <div class="card_content">
                                <h5 class="pb-3">Ratings:
                                    <star-rating 
                                        :increment="0.01"
                                        :max-rating="5"
                                        :rating="plan.ratings"
                                        active-color="#2f7eed"
                                        active-border-color="#2f7eed"
                                        :border-width="1"
                                        border-color="#d5d5d5"
                                        :show-rating="false"
                                        :star-size="10"
                                        :read-only="true"
                                        :rounded-corners="true"
                                        inactive-color="#d5d5d5"
                                    ></star-rating>
                                </h5>
                                <h5 class="pb-3">{{ plan.workouts_count }} Workouts <p class="mb-0">{{ plan.completed_workouts }} Completed</p></h5>
                                <h5 class="pb-3">{{ plan.blocks_count }} Blocks <p class="mb-0">{{ plan.completed_blocks }} Completed</p></h5>
                                <h5 class="pb-3">{{ plan.weeks_count }} Weeks</h5>
                                <h5>Notes:</h5>
                                <p>{{ plan.notes ? plan.notes : 'N/A' }}</p>
                            </div>
                            <div class="card_footer">
                                <h5>Assigned on:</h5>
                                <span class="date">
                                    {{ moment.utc(plan.created_at).local().format('ll | LT') }}
                                    <span class="text-danger" v-if="plan.deleted_at">[deleted]</span>
                                </span>
                            </div>
                        </profile-card>
                    </li>
                </ul>
                <div class="empty_list" v-if="contactWorkoutPlans.total == 0 && !contactWorkoutPlanLoader">
                    <img src="@/assets/images/empty_state.svg">
                    <h4>No Records Found!</h4>
                </div>
            </template>
        </div>
        <div  v-if="tab == 2">
            <div class="pagination">
                <pagination v-model="workoutPlanParams.page" :pages="contactWorkoutPlans.last_page" @update:modelValue="handleWorkoutPlanPagination"/>
            </div>
            <div class="form_view" v-if="showWorkoutPlan">
                <button class="close_btn pointer" @click="showWorkoutPlan = false"><i class="fas fa-times"></i></button>
                <workout-plan-chart :contact="contact" :plan-id="selectedWorkoutPlanRelation.id" />
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    import StarRating from 'vue-star-rating'

    const ProfileCard = defineAsyncComponent(() => import('@/pages/contact/components/profile/ProfileCard'))
    const WorkoutsChart = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutChart'))
    const WorkoutPlanChart = defineAsyncComponent(() => import('@/pages/workout/components/plan/WorkoutPlanChart'))

    import moment from 'moment'

    export default {
        name: 'Tab Workouts',

        data () {
            return {
                tab: 1,
                workoutActions: [
                    {
                        icon: 'fa-search-plus',
                        label: 'View',
                        action: 'viewWorkout'
                    },
                ],
                workoutPlanActions: [
                    {
                        icon: 'fa-search-plus',
                        label: 'View',
                        action: 'viewWorkoutPlan'
                    },
                ],
                workoutParams: {
                    page: 1,
                    per_page: 6,
                    contact_id: '',
                    search: '',
                },

                workoutPlanParams: {
                    page: 1,
                    per_page: 6,
                    contact_id: '',
                    search: '',
                },

                moment,
                isTyping: false,
                showWorkout: false,
                showWorkoutPlan: false,
                selectedWorkoutRelation: {},
                selectedWorkoutPlanRelation: {},
                search: '',
                perPage: 6,
            };
        },

        props: {
            contact: Object,
        },

        components: {
            ProfileCard,
            StarRating,
            WorkoutsChart,
            WorkoutPlanChart,
        },

        watch: {
            perPage (perPage) {
                const vm = this;

                vm.workoutParams.page = 1;
                vm.workoutPlanParams.page = 1;
                vm.workoutParams.per_page = perPage;
                vm.workoutPlanParams.per_page = perPage;

                if (vm.tab == 1) {
                    vm.getContactWorkouts(vm.workoutParams);
                } else {
                    vm.getContactWorkoutPlans(vm.workoutPlanParams);
                }
            },

            search (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.search != null) {
                        if (vm.search.length >= 2) {
                            vm.workoutParams.search = '';
                            vm.workoutPlanParams.search = '';

                            if (vm.tab == 1) {
                                vm.workoutParams.page = 1;
                                vm.workoutParams.search = vm.search;

                                vm.getContactWorkouts(vm.workoutParams);
                            } else {
                                vm.workoutPlanParams.page = 1;
                                vm.workoutPlanParams.search = vm.search;

                                vm.getContactWorkoutPlans(vm.workoutPlanParams);
                            }
                        }

                        if (vm.search.length === 0) {
                            vm.workoutParams.search = '';
                            vm.workoutPlanParams.search = '';

                            if (vm.tab == 1) {
                                vm.workoutParams.page = 1;
                                vm.workoutParams.search = vm.search;

                                vm.getContactWorkouts(vm.workoutParams);
                            } else {
                                vm.workoutPlanParams.page = 1;
                                vm.workoutPlanParams.search = vm.search;

                                vm.getContactWorkoutPlans(vm.workoutPlanParams);
                            }
                        }
                    }
                }
            },

            tab (tab) {
                const vm = this;

                vm.search = '';

                if (tab == 1) {
                    vm.getContactWorkouts(vm.workoutParams);
                } else {
                    vm.getContactWorkoutPlans(vm.workoutPlanParams);
                }
            }
        },

        computed: mapState({
            contactWorkouts: state => state.workoutModule.contactWorkouts,
            contactWorkoutLoader: state => state.workoutModule.contactWorkoutLoader,
            contactWorkoutPlans: state => state.workoutModule.contactWorkoutPlans,
            contactWorkoutPlanLoader: state => state.workoutModule.contactWorkoutPlanLoader,
        }),

        mounted () {
            const vm = this;
            vm.workoutParams.contact_id = vm.contact.id;
            vm.workoutPlanParams.contact_id = vm.contact.id;

            vm.getContactWorkouts(vm.workoutParams);
            vm.getContactWorkoutPlans(vm.workoutPlanParams);
        },

        methods: {
            ...mapActions({
                getContactWorkouts: 'workoutModule/getContactWorkouts',
                getContactWorkoutPlans: 'workoutModule/getContactWorkoutPlans',
            }),

            workoutActionHandler (workout) {
                const vm = this;

                vm.selectedWorkoutRelation = workout;
                vm.showWorkout = true;
            },

            workoutPlanActionHandler (workoutPlan) {
                const vm = this;

                vm.selectedWorkoutPlanRelation = workoutPlan;
                vm.showWorkoutPlan = true;
            },

            handleWorkoutPagination (page) {
                const vm = this;

                vm.workoutParams.page = page;
                vm.getContactWorkouts(vm.workoutParams);
            },

            handleWorkoutPlanPagination (page) {
                const vm = this;

                vm.workoutPlanParams.page = page;
                vm.getContactWorkoutPlans(vm.workoutPlanParams);
            },

            viewWorkout (workout) {
                const vm = this;

                vm.selectedWorkoutRelation  = workout.workout ? workout.workout : {};
                vm.showWorkout              = true;
            },

            viewWorkoutPlan (plan) {
                const vm = this;

                vm.selectedWorkoutPlanRelation  = plan.workout_plan ? plan.workout_plan : {};
                vm.showWorkoutPlan              = true;
            },
        }
    }
</script>

<style scoped>
    .notes_wpr{
        overflow-y: scroll;
        flex: 1;
    }
    .notes_wpr::-webkit-scrollbar {
        display: none;
    }
    .profile_notes .title h3{
        align-items: center;
        flex-grow: 1;
    }
    .card_listing .score{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
    .card_listing .score li{
        width: 50%;
        padding: 0 10px;
    }
    :deep(.form_view .report_section .habit_container){
        padding: 0 10px;
    }
    :deep(.form_view .loader_area.no_sidebar){
        position: static;
    }
    .tab_list{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 -15px;
    }
    .tab_list li{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 0 15px;
        cursor: pointer;
    }
    .tab_list li.active{
        font-weight: 500;
        color: #0e101a;
    }
    .tab_list li:not(:last-child){
        border-right: 1px solid #cecece;
    }
    :deep(.note_card h5){
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @media(max-width: 600px){
        .profile_notes .card_listing li{
            flex: 0 0 100%;
        }
        .profile_notes .title{
            flex-wrap: wrap;
        }
        .profile_notes .title h3{
            width: 100%;
            margin-bottom: 15px;
        }
        .profile_notes .title .right_side{
            width: 100%;
        }
        .profile_notes .title .right_side .search_panel{
            margin: 0 auto 0 0;
        }
    }
</style>
